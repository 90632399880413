<template>

  <div>

    <b-card>
      Nesta página você vê as informações dos pacientes que optaram por compartilhar os dados de tratamentos com você. Utilize os filtros para buscar por nome, status ou condições de saúde relatadas.
    </b-card>

    <b-row>

      <b-col cols="6">
        <b-card>
          <div>
            Pacientes vinculados
          </div>
          <h1 class="mt-1">
            <strong>{{ countMembers }}</strong>
          </h1>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card>
          <div>
            Adesão média dos últimos 30 dias
          </div>
          <h1 class="mt-1">
            <strong>{{ average }} </strong>
          </h1>
        </b-card>
      </b-col>

    </b-row>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="6"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                type="text"
                placeholder="Digite o nome do paciente"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>itens</label>
          </b-col>
        </b-row>

        <b-row>
          <div class="m-1 mt-2">
            <b-button
              variant="outline-primary"
              @click="show"
            >
              <feather-icon
                icon="FilterIcon"
                class="mr-50"
              />
              Filtrar
            </b-button>
          </div>
        </b-row>

        <template v-if="showFilter">
          <div class="mt-1 border">
            <div class="m-2">
              <strong>Status</strong>
              <div class="mt-1">
                <b-row class="m-2">
                  <b-form-checkbox
                    v-model="statusFilter"
                    value="ACTIVE"
                  >
                    Ativo
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="statusFilter"
                    value="INACTIVE"
                    class="ml-2"
                  >
                    Inativo
                  </b-form-checkbox>
                </b-row>
              </div>
              <hr>
              <strong class="mt-2">Condições de saúde</strong>
              <div class="m-2">
                <v-select
                  v-model="searchCare"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsCare"
                  :reduce="optionsCare => optionsCare.care_id"
                  multiple
                  label="name"
                  placeholder="condições de saúde"
                />
              </div>
              <hr>
              <b-button
                variant="outline-primary"
                @click="clear()"
              >
                Limpar filtros
              </b-button>
              <!-- <b-button
                class="ml-2"
                variant="outline-primary"
              >
                Aplicar filtros
              </b-button> -->
            </div>
          </div>
        </template>

      </div>

      <b-table
        ref="refPatientListTable"
        class="position-relative"
        :items="fetchPatients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="
          searchQuery.length === 0
            ? 'Você ainda não possui pacientes vinculados.'
            : 'Nenhum paciente encontrado.'
        "
        :sort-desc.sync="isSortDirDesc"
        fixed
      >
        <template #cell(patient)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar_url"
                :text="avatarText(data.item.name)"
                variant="primary"
                :badge-variant="data.item.weight_warning ? 'yellow' : ''"
                :badge="data.item.weight_warning ? '!' : ''"
                badge-offset="-0.5em"
                class="mr-1"
              />
            </template>
            {{ data.item.name }}
            <br>
            <template v-if="data.item.birthdate">
              <small>{{ formatDateBR(data.item.birthdate) }} - {{ data.item.age }} anos</small>
            </template>
          </b-media>
        </template>

        <template #cell(condition)="data">
          {{ formatString(data.item.member_cares_name) }}
        </template>

        <template #cell(adherence)="data">
          {{ data.item.adherence }} %
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePatientStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ translateBr(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="outline-primary"
            @click="edit(data.item)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            Visualizar
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPatients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <patient-help />
  </div>
</template>

<script>
import moment from 'moment'
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BBadge, BButton, BFormCheckbox,
  BPagination, VBModal, BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import avatarText from '@/utils/filter'
// import PatientsListFilters from './PatientsListFilters.vue'
import usePatientsList from './usePatientsList'
import PatientHelp from './Patient-help.vue'

export default {
  components: {
    // PatientsListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BButton,
    BFormCheckbox,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    PatientHelp,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewPatientSidebarActive = ref(false)

    const {
      fetchPatients,
      tableColumns,
      perPage,
      currentPage,
      totalPatients,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchCare,
      sortBy,
      isSortDirDesc,
      refPatientListTable,
      refetchData,

      // UI
      resolvePatientRoleVariant,
      resolvePatientRoleIcon,
      resolvePatientStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = usePatientsList()

    return {

      // Sidebar
      isAddNewPatientSidebarActive,

      fetchPatients,
      tableColumns,
      perPage,
      currentPage,
      totalPatients,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchCare,
      sortBy,
      isSortDirDesc,
      refPatientListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePatientRoleVariant,
      resolvePatientRoleIcon,
      resolvePatientStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
      selectStatus: [],
      average: '',
      showFilter: false,
      countMembers: 0,
      optionsCare: '',
    }
  },

  computed: {
    showHelp() {
      return this.$store.state.breadcrumb.showHelp
    },
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-center')
      }
    },
  },

  mounted() {
    this.$store.commit('patient/RESET')
    this.countMember()
    this.averageAdherence()
    this.listCare()
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    clear() {
      this.statusFilter = false
      this.searchCare = ''
    },
    countMember() {
      this.$store.dispatch('patient/countMembers')
        .then(response => {
          this.countMembers = response.data.data.members_count
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    averageAdherence() {
      this.$store.dispatch('resume/averageAdherence')
        .then(response => {
          const average = response.data.data.members_adherence_average
          this.average = `${Math.round(average)}%`
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    listCare() {
      this.$store.dispatch('patient/listCare')
        .then(response => {
          this.optionsCare = response.data.data.member_cares_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    formatString(value) {
      const lengthArray = value.length
      let stringWeek = ''

      value.forEach((valueArray, index) => {
        stringWeek += valueArray
        if (index < lengthArray - 2) {
          stringWeek += ', '
        } else if (index === lengthArray - 2) {
          stringWeek += ' e '
        }
      })
      return stringWeek
    },
    edit(data) {
      this.$store.commit('patient/RESET')
      this.$store.commit('patient/UPDATE_PATIENT', data)
      this.$store.commit('patient/UPDATE_SHOW_TAB', true)
      this.$router.push('patient-edit')
    },
    show() {
      if (this.showFilter === true) {
        this.showFilter = false
      } else {
        this.showFilter = true
      }
    },
    translateBr(value) {
      switch (value) {
        case 'ACTIVE':
          return 'ATIVO'
        case 'INACTIVE':
          return 'INATIVO'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.badge-yellow {
  background-color: #F8C52F;
  color:#000;
}
</style>
